import { getDeviceType, DEVICES } from './device';

const {
  navigator,
  devicePixelRatio,
  matchMedia,
} = window;
const {
  platform,
  userAgent,
  vendor,
} = navigator;

const retinaMediaQuery = '\
	(-webkit-min-device-pixel-ratio: 1.5),\
	(min--moz-device-pixel-ratio: 1.5),\
	(-o-min-device-pixel-ratio: 3/2),\
	(min-resolution: 1.5dppx)\
';

export default {
  Chrome: () => /Chrome/.test(userAgent) && /Google Inc/.test(vendor),
  safari: () => /safari/i.test(userAgent) && !/chrome/i.test(userAgent),
  firefox: () => /firefox/i.test(userAgent),
  ie: () => userAgent.indexOf('Trident') !== -1,
  edge: () => userAgent.indexOf('Edge') !== -1,
  mac: () => platform.match(/(Mac|iPhone|iPod|iPad)/i),
  ios: () => platform.match(/(iPhone|iPod|iPad)/i),
  retina: () => devicePixelRatio > 1 || matchMedia(retinaMediaQuery).matches,
  mobile: () => /mobile/i.test(userAgent)
    || userAgent.match(/Android|webOS|Blackberry/i),
  mobileDevice: () => [DEVICES.PHONE, DEVICES.TABLET].includes(getDeviceType()),
  phoneDevice: () => DEVICES.PHONE === getDeviceType(),
  tabletDevice: () => DEVICES.TABLET === getDeviceType(),
  isInFrame: window.parent.self !== window.self, // true - in iframe
};
