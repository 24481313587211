import axios from 'axios';
import axiosRetry from 'axios-retry';

import merge from '@sp/core/helper/lodash/merge';
import omit from '@sp/core/helper/lodash/omit';
import { logout } from '@sp/core/helper/redirect';

import cookie from './cookie';

const TOKEN = 'accessToken';

export const CANCEL_MSG = 'CANCEL_MSG';
export const CANCEL_CODE = 'CANCEL_CODE';

class Wrapper {
  constructor(host, options = {}) {
    const _options = {
      baseURL: host,
      ...options,
    };

    this.options = _options;
    this.axios = axios.create(_options);
    axiosRetry(this.axios, {
      retries: 3,
      retryDelay: () => 3000,
      retryCondition: ({
        config: { retryable = true },
        request,
        response,
      }) => request
        && (!response
          || (response.status >= 500 && response.status < 600))
        && retryable,
    });

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (axios.isCancel(error)) {
          const err = new Error(CANCEL_MSG);

          err.code = CANCEL_CODE;

          return Promise.reject(err);
        }

        const status = error?.response?.status;
        const type = error?.response?.data?.type;

        if (
          status
          && status === 403
          && type
          && type === 'authentication'
        ) {
          logout();
        }

        return Promise.reject(error);
      }
    );
  }

  get accessToken() {
    return cookie.get(TOKEN);
  }

  getParams(params) {
    const headers = {
      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
    };
    const defaultHeaders = this?.options?.headers || {};

    const result = {
      ...this.options,
      ...params,
      headers: {
        ...defaultHeaders,
        ...headers,
        ...(params && params.headers),
      },
    };

    const token = this.accessToken;

    if (token && defaultHeaders.Authorization === undefined) {
      const aHeaderObj = { Authorization: `Bearer ${token}` };

      result.headers = result.headers
        ? {
          ...result.headers,
          ...aHeaderObj,
        }
        : aHeaderObj;
    }

    // Filter empty headers
    result.headers = Object.keys(result.headers)
      .reduce((prev, key) => {
        if (result.headers[key]) prev[key] = result.headers[key];

        return prev;
      }, {});

    return result;
  }

  createRequest({
    method,
    url,
    data,
    config,
    withParams,
  }) {
    let cancel;
    const source = config?.cancelTokenSource;
    const { CancelToken } = axios;
    const reqConfig = withParams ? this.getParams(config) : config;
    const requestConfig = {
      url,
      method,
      ...reqConfig,
      cancelToken: source
        ? source.token
        : new CancelToken((c) => (cancel = c)),
    };

    if (data) requestConfig.data = data;

    const request = this.axios(requestConfig);

    if (source) {
      cancel = source.cancel;
    }

    request.cancelRequest = cancel;

    return request;
  }

  get = (url, params, config) => this.createRequest({
    url,
    config: merge(
      {
        params: params?.withoutAccess ? omit(params, ['withoutAccess']) : merge(
          {
            accessToken: this.accessToken,
            timestamp: Date.now(),
          },
          params
        ),
      },
      config
    ),
    method: 'get',
  });

  post = (url, data, config) => this.createRequest({
    url,
    config,
    data,
    method: 'post',
    withParams: true,
  });

  put = (url, data, config) => this.createRequest({
    url,
    config,
    data,
    method: 'put',
    withParams: true,
  });

  delete = (url, data, config) => this.createRequest({
    url,
    data,
    method: 'delete',
    config: merge(
      {
        params: merge(
          {
            accessToken: this.accessToken,
          },
          data
        ),
      },
      config
    ),
  });
}

export default Wrapper;
