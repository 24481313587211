const PROTOCOL = 'https';
const apiUrls = {
  ...window.API_URLS,
};

const DEFS = {
  staticService: 'https://static-cdn.edit.site',
  backupApiService: 'https://backup-api-service.siteplus.wtf',
};

Object.keys(DEFS)
  .forEach((serviceKey) => {
    if (!apiUrls[serviceKey]) apiUrls[serviceKey] = DEFS[serviceKey];
  });

const {
  blogApi: BLOG_API_SERVICE,
  frontendApp: FRONTEND_APP_SERVICE,
  fileStorageService: FS_API_SERVICE,
  imageApiService: IMAGE_API_SERVICE,
  editorApiService: EDITOR_API_SERVICE,
  getScreenshotsUrl: GET_SCREENSHOT_URL,
  editorSocketService: EDITOR_SOCKET_SERVICE,
  static: STATIC_SERVICE,
  domainApiService: DOMAIN_API_SERVICE,
  memberApiService: MEMBER_API_SERVICE,
  embedApiService: EMBED_API_SERVICE,
  analyticApiService: ANALYTIC_API_SERVICE,
  projectApiService: PROJECT_API_SERVICE,
  geoipService: GEOIP_API_SERVICE,
  staticService: STATIC_API_SERVICE,
  websitePublishUrl: WEBSITE_PUBLISH_URL,
  helpCenterApi: HELP_CENTER_API,
  blogFrontendService: BLOG_FRONTEND_HOST,
  pageRenderService: PAGE_RENDER_SERVICE,
  siteWizardFrontendService: SITE_WIZARD_FRONTEND_HOST,
  backupApiService: BACKUP_SERVICE,
  screenshotMaker: SCREENSHOT_MAKER,
  editorApiPhp: EDITOR_API_PHP,
} = apiUrls;

export {
  BLOG_API_SERVICE,
  FRONTEND_APP_SERVICE,
  FS_API_SERVICE,
  GET_SCREENSHOT_URL,
  IMAGE_API_SERVICE,
  EDITOR_API_SERVICE,
  EDITOR_SOCKET_SERVICE,
  STATIC_SERVICE,
  DOMAIN_API_SERVICE,
  MEMBER_API_SERVICE,
  EMBED_API_SERVICE,
  ANALYTIC_API_SERVICE,
  PROJECT_API_SERVICE,
  PROTOCOL,
  GEOIP_API_SERVICE,
  STATIC_API_SERVICE,
  WEBSITE_PUBLISH_URL,
  HELP_CENTER_API,
  BLOG_FRONTEND_HOST,
  PAGE_RENDER_SERVICE,
  SITE_WIZARD_FRONTEND_HOST,
  BACKUP_SERVICE,
  SCREENSHOT_MAKER,
  EDITOR_API_PHP,
};

export default apiUrls;
