import fmwConverter from '@sp/core/api/converters/fmwConverter';

import blogConverter from './blog';
import ecommerceConverter from './ecommerce';
import pageConverter from './pageConverter';
import stylesConverter from './styles';

const sectionConverters = (sections) => {
  const converters = [
    pageConverter,
    fmwConverter,
  ];

  const build = {
    pages: {
      structure: sections,
    },
  };

  const prepareBuild = converters.reduce((prev, converter) => {
    const newBuild = converter(build);

    if (!newBuild) return prev;

    return newBuild;
  }, build);

  return prepareBuild.pages.structure;
};

const menuConverter = (build) => {
  const converters = [
    ecommerceConverter,
    blogConverter,
    stylesConverter,
  ];

  const prepareBuild = converters.reduce((prev, converter) => {
    const newBuild = converter(build);

    if (!newBuild) return prev;

    return newBuild;
  }, build);

  return prepareBuild;
};

export {
  menuConverter,
  sectionConverters,
};
