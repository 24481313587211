import axios from 'axios';
import moment from 'moment';

import { formatDate } from '@sp/core/helper/date';
import get from '@sp/core/helper/lodash/get';
import isEmpty from '@sp/core/helper/lodash/isEmpty';

import is from './browser/is';
import chain from './chain';

export const isStoreConnectLimit = (connectedCount) => !connectedCount
  || connectedCount?.active >= connectedCount?.total;

export const preventByCors = (url) => new Promise((resolve) => {
  axios
    .options(url)
    .then(() => resolve(false))
    .catch((error) => {
      resolve(error.response === undefined);
    });
});

export function arrayMove(arr, previousIndex, newIndex) {
  const array = [...arr];

  if (newIndex >= array.length) {
    let k = newIndex - array.length;

    while (k-- + 1) {
      array.push(undefined);
    }
  }

  array.splice(newIndex, 0, array.splice(previousIndex, 1)[0]);

  return array;
}

export function getUserData() {
  const urlArr = window.location.href.split('/');
  const page = urlArr[3];
  const projectId = urlArr[4];
  let pageId = (urlArr[5] || '').replaceAll(/#.*/gim, '');

  if (pageId?.length > 36) pageId = pageId.slice(0, 36);

  return {
    projectId,
    pageId,
    page,
  };
}

function getStatePart(state, path, defaultValue) {
  if (isEmpty(state)) return path ? defaultValue : {};

  return path ? get(state, path, defaultValue) : state;
}

export function getPreloadedState(path, defaultValue) {
  const state = window.__PRELOADED_STATE__ || {};

  return getStatePart(state, path, defaultValue);
}

export const getJSONAsync = (name) => new Promise((resolve, reject) => {
  try {
    require([`@sp/json/${name}.json`], (res) => {
      resolve(res);
    });
  } catch (error) {
    reject(error);
  }
});

export function toDate(date, time = false, custom) {
  const dateISO = date && formatDate(date);
  let ser_date = dateISO ? moment(new Date(dateISO)) : moment(new Date());

  if (custom != null) {
    return moment(ser_date)
      .format(custom);
  }

  ser_date = moment(ser_date, 'dd/mmm/yyyy');

  return ser_date.calendar();
}

export function generatePinterestClass({
  active,
  size,
  type,
  color,
}) {
  if (!active) return '';

  const baseClass = 'pin_it_button';
  const sizes = [
    [20, 28],
    [16, 32],
  ];
  const colors = ['red', 'gray', 'white'];
  const sizeClass = `${baseClass}_${sizes[type][size]}`;
  const colorClass = `${sizeClass}_${colors[type ? 0 : color]}`;
  const typeClass = type ? `${colorClass}_round` : '';
  const retinaClass = is.retina() ? `${colorClass}_retina` : '';

  return `${baseClass} ${sizeClass} ${colorClass} ${typeClass} ${retinaClass}`;
}

export const requestAnimationFrame = (() => (
  window.requestAnimationFrame
    || window.webkitRequestAnimationFrame
    || window.msRequestAnimationFrame
    || window.mozRequestAnimationFrame
    || window.oRequestAnimationFrame
    || ((callback, element, delay) => window.setTimeout(callback, delay || 1000 / 60))
).bind(window))();

export const cancelAnimationFrame = (() => (
  window.cancelAnimationFrame
    || window.webkitCancelRequestAnimationFrame
    || window.mozCancelRequestAnimationFrame
    || window.oCancelRequestAnimationFrame
    || window.msCancelRequestAnimationFrame
    || clearTimeout
).bind(window))();

/**
 * History object used in auth
 */
export { chain };
