import bodyClass from '@sp/core/helper/dom/bodyClass';

let prevTop = 0;
const DELAY = 200;

/**
 * Check scroll position and apply 'help-box-hidden' class
 * if scroll on bottom
 * @see https://jira.siteplus.com/browse/SP-64804
 *
 * @param {object} values custom scroll data
 */
export default ({ top }, delay = DELAY) => {
  if (top === 1 && prevTop !== 1) {
    setTimeout(() => bodyClass.set({ hideHelpBox: true }), delay);
  }
  if (top !== 1 && prevTop === 1) {
    setTimeout(() => bodyClass.set({ hideHelpBox: false }), delay);
  }

  prevTop = top;
};
