export const PAGE_TYPE_FOLDER = 'folder';
export const PAGE_TYPE_GROUP = 'group';
export const PAGE_TYPE_LINK = 'link';
export const PAGE_TYPE_PAGE = 'page';
export const PAGE_TYPE_ANCHOR = 'anchor';

export const PAGE_TYPE_BLOG = 'blog';
export const PAGE_TYPE_BLOG_POST = 'blogPost';

export const PAGE_TYPE_CATALOG = 'ecommerce';
export const PAGE_TYPE_CART = 'ecommerceCart';
export const PAGE_TYPE_SHIPPING = 'ecommerceShipping';
export const PAGE_TYPE_PRODUCT = 'ecommerceProduct';

export const PAGE_TYPE_BOOKING = 'booking';

export const PAGE_TYPE_COOKIE_POLICY = 'cookiesPolicy';

export const NON_PAGE_TYPES = [
  PAGE_TYPE_LINK,
  PAGE_TYPE_FOLDER,
  PAGE_TYPE_ANCHOR,
  PAGE_TYPE_GROUP,
];

const ALL_TYPES_MAP = {
  FOLDER: PAGE_TYPE_FOLDER,
  GROUP: PAGE_TYPE_GROUP,
  LINK: PAGE_TYPE_LINK,
  PAGE: PAGE_TYPE_PAGE,
  ANCHOR: PAGE_TYPE_ANCHOR,

  BLOG: PAGE_TYPE_BLOG,
  BLOG_POST: PAGE_TYPE_BLOG_POST,

  CATALOG: PAGE_TYPE_CATALOG,
  CART: PAGE_TYPE_CART,
  SHIPPING: PAGE_TYPE_SHIPPING,
  PRODUCT: PAGE_TYPE_PRODUCT,

  COOKIE_POLICY: PAGE_TYPE_COOKIE_POLICY,

  BOOKING: PAGE_TYPE_BOOKING,
};

export const ALL_TYPES_VAL = Object.values(ALL_TYPES_MAP);

export const TYPES_HIDE_NAV = [
  // blog
  PAGE_TYPE_BLOG_POST,
  // Ecommerce
  PAGE_TYPE_PRODUCT,
  PAGE_TYPE_CART,
  PAGE_TYPE_SHIPPING,
];

export const isPageType = (type) => !NON_PAGE_TYPES.includes(type?.toLowerCase());

export const isSystemType = (type) => {
  const prepareType = [
    PAGE_TYPE_BLOG_POST,
    PAGE_TYPE_CART,
    PAGE_TYPE_SHIPPING,
    PAGE_TYPE_PRODUCT,
    PAGE_TYPE_COOKIE_POLICY,
  ].includes(type) ? type : type?.toLowerCase();

  return (
    type
    && ALL_TYPES_VAL.includes(prepareType)
    && ![...NON_PAGE_TYPES, PAGE_TYPE_PAGE].includes(prepareType)
  );
};

export const isNavHide = (type) => !!type && TYPES_HIDE_NAV.includes(type);

export const isSystemPage = (page) => {
  const isSystemByType = !!page.type && isSystemType(page.type);
  const hasSystemMarker = !!page.blog || !!page.post || !!page.ecommerce || !!page.product;

  return isSystemByType || hasSystemMarker;
};

export default ALL_TYPES_MAP;
