import { sectionConverters } from '@sp/core/api/converters';
import cookie from '@sp/core/api/cookie';
import { getUserData } from '@sp/core/helper';
import memoize from '@sp/core/helper/lodash/memoize';
import PROJECT from '@sp/core/helper/projectInfo';

import getHome from './utils/getHome';
import mergeProjectBuild from './utils/mergeProjectBuild';
import { MEMBER_API_SERVICE as host } from './api_urls';
import Wrapper from './axios_wrapper';

export const instance = new Wrapper(host);

/**
 * Member authenticate
 * @method POST
 * @return Promise
 */

export const postAuth = (data) => instance.post('auth', data);

/**
 * Check member authorization
 * @method GET
 * @return Promise
 */

export const isAuthenticated = () => instance.get('auth');

/**
 * Member sessions list
 * @method GET
 * @param {Object} data
 * @return Promise
 */
export const getSessions = (data) => instance.get('sessions', data);

/**
 * Delete session by id
 * @method DELETE
 * @param {string} id
 * @return Promise
 */
export const deleteSession = (id) => instance.delete(`sessions/${id}`);

/**
 * Get member info
 * @method GET
 * @return Promise
 */

// eslint-disable-next-line no-return-await
export const getInfo = async () => await instance.get('account');

/**
 * Update member info
 * @method PUT
 * @param {object} data Request data
 * @return Promise
 */

export const setInfo = (data) => instance.put('account', data);

/**
 * Returns data about reseller permissions
 * @method GET
 * @return Promise
 */
export const getAccountPermissions = memoize(() => instance.get('account/permission'));

/**
 * Returns data about ecommerceInfo
 * @method GET
 * @return Promise
 */
export const getEcommerceInfo = () => instance.get('account/ecommerce');

/**
 * Get member overview
 * @method GET
 * @return Promise
 */
export const getAccountOverview = () => instance.get('account/overview');

/**
 * Gets project list
 * @method GET
 * @param {b|NULL=} deleted - if deleted is undefined - returns all project | true - only deleted project | false
 *   - not deleted projects
 * @return Promise
 */

export const getProjectList = (deleted) => {
  let data = null;

  if (deleted !== undefined) {
    data = {
      deleted,
    };
  }

  return instance.get('projects', data);
};

export const getTemplateList = memoize(
  async ({
    editor,
    ...data
  } = {}) => instance.get(
    `v2.0/templates${editor ? `?editor=${editor}` : ''}`,
    data
  )
);

/**
 * Gets one subscription by planId
 * @method GET
 * @param {integer|string} data - planId
 * @return Promise
 */
export const getSubscription = (id) => instance.get(`subscriptions/${id}`);

/**
 * Get project by id
 * @param {string} projectId
 * @returns Promise
 */
export const getProject = (projectId) => instance.get(`projects/${projectId}`);

/**
 * Duplicate project
 * @param {string} projectId
 * @returns Promise
 */
export const cloneProject = (projectId) => instance.post(`projects/${projectId}/clone`);

/**
 * Delete project
 * @param {string} projectId
 * @returns Promise
 */
export const deleteProject = (projectId) => instance.put(`projects/${projectId}/delete`);

/**
 * Restore project
 * @param {string} projectId
 * @returns Promise
 */
export const restoreProject = (projectId) => instance.put(`projects/${projectId}/undelete`);

/**
 * Create project
 * @param {string} templateId
 * @param {b/null} restoreStore
 * @returns Promise
 */
export const createProject = (templateId, restoreStore = false) => instance.post('projects', {
  templateId,
  restoreStore,
  imageComponent: 'v2',
});

/**
 * Refresh project
 * @param {string} projectId
 * @returns Promise
 */
export const refreshContent = (projectId) => instance.post(`projects/${projectId}/refresh-content`);

/**
 * Create project by Site Wizard
 * @param {string} sitewizardId
 * @param {b/null} restoreStore
 * @returns Promise
 */
export const createProjectBySiteWizard = (sitewizardId, restoreStore = false) => instance.post('projects', {
  sitewizardId,
  restoreStore,
  imageComponent: 'v2',
  accessToken: cookie.get('accessToken'),
}, { retryable: false });

/**
 * Check project by Site Wizard
 * @param {string} sitewizardId
 * @returns Promise
 */
export const checkProjectBySiteWizard = (sitewizardId) => instance.get(`projects/${sitewizardId}/status`);

/**
 * Publish project
 * @param {string?} projectId
 * @param {string?} domainId
 * @returns Promise
 */
export const publishProject = (projectId, domainId) => {
  window.localStorage.removeItem('mySitesData_projects');

  return instance.put(`projects/${projectId || PROJECT.ID}/publish`, {
    domainId,
    assetsVersion: window.CI_PIPELINE_ID,
  });
};
/**
 * Publish project
 * @param {string?} projectId
 * @param {string?} domainId
 * @returns Promise
 */
export const updatePublishedProject = (projectId, domainId) => {
  window.localStorage.removeItem('mySitesData_projects');

  return instance.put(`projects/${projectId || PROJECT.ID}/update`, {
    domainId,
    assetsVersion: window.CI_PIPELINE_ID,
  });
};
/**
 * Publish project
 * @param {string?} projectId
 * @param {string} domainId
 * @returns Promise
 */
export const publishProjectToPartnerDomain = (projectId, domainId) => {
  window.localStorage.removeItem('mySitesData_projects');

  return instance.put(`partner/${projectId || PROJECT.ID}/publish`, {
    domainId,
    assetsVersion: window.CI_PIPELINE_ID,
  });
};

/**
 * Unpublish project
 * @param {string} projectId
 * @returns Promise
 */
export const unpublishProject = (projectId) => {
  window.localStorage.removeItem('mySitesData_projects');

  return instance.put(`projects/${projectId}/unpublish`);
};

/**
 * Update notifications data
 * @param {object} data
 * @returns Promise
 */
export const updateNotifications = (data) => instance.put('account/notifications', data);

/**
 * Check if user is authenticated every 15 min
 * Works on prod only
 * @param onReject
 * @param timeout
 * @return {Object}
 */
export const createAuthInterval = (onReject, timeout = 15 * 60 * 1000) => {
  const check = () => isAuthenticated()
    .catch(onReject);

  return setInterval(check, timeout);
};

/**
 * Get pages sections
 * @method GET
 * @param {string} pageId - page id
 * @return Promise
 */
export const getPageSections = async (pageId) => {
  const response = await instance.get(`single-page-render/${pageId}/sections`);

  let sections = response.data;

  if (typeof sections === 'string') {
    const jsonFix = sections.replaceAll('}{', '},{');
    const jsonWrap = `[${jsonFix}]`;

    sections = JSON.parse(jsonWrap);
  }

  if (!Array.isArray(sections)) {
    sections = [sections];
  }

  return sectionConverters(sections);
};

/**
 * Get pages sections
 * @method GET
 * @param {string} projectId - project id
 * @return Promise
 */
export const getPages = async (projectId) => {
  const response = await instance.get(`single-page-render/${projectId}`);

  const { data } = response;

  return data;
};

/**
 * Get project header
 * @method GET
 * @param {string} projectId - project id
 * @return Promise
 */
export const getProjectHeader = async (projectId) => {
  const { data } = await instance.get(`technical/projects/${projectId}/build/header`);

  return data;
};

/**
 * Get project footer
 * @method GET
 * @param {string} projectId - project id
 * @return Promise
 */
export const getProjectFooter = async (projectId) => {
  const { data } = await instance.get(`technical/projects/${projectId}/build/footer`);

  return data;
};

/**
 * Get project menu
 * @method GET
 * @param {string} projectId - project id
 * @return Promise
 */
export const getProjectMenu = async (projectId) => {
  const { data } = await instance.get(`technical/projects/${projectId}/build/menu`);
  const {
    list, hidden, outOfPlan, unlinked,
  } = data;

  return {
    list, hidden, outOfPlan, unlinked,
  };
};

/**
 * Get build of project
 * @param {string} id - project id
 * @param {Object=} build - build response
 * @param {Object=} settings - build settings
 */
export const getProjectBuild = async (id, build, settings = {}) => {
  const { reloadMode, backupMode } = settings;
  let { pageId } = getUserData();
  let prepareMenu = null;

  if (reloadMode || backupMode) {
    prepareMenu = await getProjectMenu(id);

    const homePageData = getHome(prepareMenu);

    pageId = homePageData?.hash;
  }

  const [
    resp,
    header,
    menu,
    footer,
    pages,
    sections,
  ] = await Promise.all([
    ...build ? [build] : [instance.get(`projects/${id}/build`)],
    getProjectHeader(id),
    (reloadMode || backupMode) ? prepareMenu : getProjectMenu(id),
    getProjectFooter(id),
    getPages(id),
    pageId ? getPageSections(pageId) : null,
  ]);

  return mergeProjectBuild(resp, {
    header,
    menu,
    footer,
    pages: {
      ...pages,
      ...pageId && { [pageId]: sections },
    },
  });
};

/**
 * Get onBoardingSteps by its id
 * @param {number} id
 */
export const onBoardingSteps = (id) => instance.get(`onboarding/${id}/step`);

/**
 * Save onBoardingSteps by its id
 * @param {number} id
 * @param params - Object with parameters stepId {string} and action {string}(skip or confirm}
 */
export const saveOnBoardingStep = (id, params) => instance.post(`onboarding/${id}/step`, params);

export const checkNeedCtbSsl = (projectId) => instance.get(`upsell/projects/${projectId}/need-ctb-ssl`);

/**
 * Update section without project history
 * @param {String} sectionId
 * @param {Object} content
 */
export const updateSectionWithoutHistory = (sectionId, content) => instance.put('technical/section', {
  data: {
    sectionId,
    content,
  },
});

/**
 * Update refresh status
 * @param {String} projectId
 * @param {boolean} status
 */
export const updateAiRefreshStatus = (projectId, status) => instance.put('technical/update-refresh-status', {
  projectId: projectId || PROJECT.ID,
  status,
});

/**
 * Get the style of the selected template
 * @method GET
 * @param {string} templateIndex
 * @return Promise
 */
export const getStyleSelectedTemplate = (templateIndex) => instance.get(`/template/styles/${templateIndex}`);

export const getAccountSetting = () => instance.get('account/settings');
export const putAccountSetting = ({
  accessToken,
  settingName,
  settingValue,
}) => instance.put(`account/settings?accessToken=${accessToken}`, {
  settingName,
  settingValue,
});
