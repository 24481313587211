import cloneDeep from '@sp/core/helper/lodash/cloneDeep';
import isObject from '@sp/core/helper/lodash/isObject';
import isString from '@sp/core/helper/lodash/isString';
import partial from '@sp/core/helper/lodash/partial';
import partialRight from '@sp/core/helper/lodash/partialRight';
import pickBy from '@sp/core/helper/lodash/pickBy';
import remove from '@sp/core/helper/lodash/remove';
import cssRootFunctions from '@sp/fixtures/json/cssRootFunctions.json';

import {
  compose,
  getRoot,
  getRootFunctions,
  intoCssRootCollection,
  mapToObject,
  mergeWithFn,
  recursiveVarSubstitute,
  tap,
  toCssRootKey,
  transformRoot,
} from './utils';

const isNode = typeof process === 'object' && !process?.browser;

const addRootFunctions = partial(
  mergeWithFn,
  partialRight(getRootFunctions, cssRootFunctions)
);

let cssVars = new Map();
const interceptors = {
  initRoot: [],
  setRoot: [],
};
const deepKeyConcat = (source) => {
  const target = [];
  const flatList = [];
  const stack = [{
    targetArr: target,
    value: source,
    flatListArr: flatList,
  }];

  while (stack.length > 0) {
    const stackElement = stack.shift();
    const { targetArr, value, flatListArr } = stackElement;

    if (typeof value !== 'object' || value === null) {
      flatListArr.push(value);
      targetArr.push(flatListArr);

      continue;
    }

    const ownKeys = Object.getOwnPropertyNames(value);

    for (let i = 0; i < ownKeys.length; i += 1) {
      const key = ownKeys[i];

      stack.push({
        targetArr,
        value: value[key],
        flatListArr: flatListArr.concat(key),
      });
    }
  }

  return target;
};
const toValidStyle = (obj) => (isObject(obj) ? pickBy(obj) : obj);
const chunkParse = (collection) => {
  const parsedRootStyles = mapToObject(cssVars);
  const newObj = cloneDeep(collection);

  return recursiveVarSubstitute(newObj, parsedRootStyles);
};

const callInterceptors = (fnName) => (...args) => interceptors[fnName].forEach((fn) => fn(...args));
const addInterceptors = (config) => {
  Object.keys(config).map((name) => interceptors[name].push(config[name]));

  return interceptors;
};
const removeInterceptors = (config) => {
  Object.keys(config).map((name) => remove(interceptors[name], config[name]));

  return interceptors;
};

const initRoot = (styles) => compose(
  tap(callInterceptors('initRoot')),
  (store) => {
    const values = Object.entries(store);

    if (!isNode) {
      const prepareStyles = values.reduce((acc, [key, value]) => `${acc}${key}:${value};`, '');

      document.documentElement.setAttribute('style', prepareStyles);
    }

    cssVars = new Map(values);

    return values;
  },
  addRootFunctions,
  intoCssRootCollection,
  deepKeyConcat
)(styles);
const setRoot = (styles) => compose(
  (source) => {
    if (isNode) return;

    const update = ([prop, value]) => {
      const key = toCssRootKey(prop);

      document.documentElement.style.setProperty(key, value);
    };

    Object.entries(source).forEach((element) => { update(element); });
  },
  tap(callInterceptors('setRoot')),
  tap((source) => {
    Object.keys(source).forEach((key) => {
      const value = source[key];

      cssVars.set(toCssRootKey(key), value);
    });
  }),
  transformRoot
)(styles);
const deleteRoot = (name) => {
  if (!isString(name)) return;

  if (!isNode) document.documentElement.style.removeProperty(name);

  cssVars.delete(name);
};
const generateFunctions = (source) => {
  const collection = mapToObject(cssVars);

  return getRootFunctions(collection, source);
};

export default {
  addInterceptors,
  callInterceptors,
  chunkParse,
  deepKeyConcat,
  deleteRoot,
  generateCSS4: intoCssRootCollection,
  getRoot: (name) => getRoot(name, mapToObject(cssVars)),
  initRoot,
  removeInterceptors,
  setRoot,
  toValidStyle,
  generateFunctions,
};
